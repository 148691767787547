import React, { useState, useEffect } from "react";
import flatstore from "flatstore";
import { useParams, useLocation } from "react-router-dom";
// Import your actual components
import SubscriptionOption from "./steps/SubscriptionOption";
import SubscriptionSize from "./steps/SubscriptionSize";
import CustomizeSubscription from "./steps/CustomizeSubscription";
import CuratedSubscription from "./steps/CuratedSubscription";
import Meta from "../../components/common/Meta";
import { isNil } from "lodash";
import { getStaticPage } from "../../utils/services";
import LoadModules from "../../components/modules/LoadModules";
import { getSubscriptionPaths, localStorageUtil } from "../../utils/functions";

const Subscription = ({ loading, subscription_static_data }) => {
  const [data, setData] = useState(null);
  const [modules, setModules] = useState(null);
  const { filter } = useParams();
  const location = useLocation();
  const paths = getSubscriptionPaths();
  const queryParams = new URLSearchParams(location.search);
  // Updated to handle trailing slash
  const parts = location.pathname.endsWith("/")
    ? location.pathname.slice(0, -1).split("/")
    : location.pathname.split("/");
  const latestPath = parts[parts.length - 1];

  const matchedStep = Object.keys(paths.steps).find((step) =>
    paths.steps[step].endsWith(latestPath)
  );

  useEffect(() => {
    subscription_static_data = null;
  }, []);

  useEffect(() => {
    const shouldReset = queryParams.get("reset") === "1";

    if (!shouldReset) {
      return;
    }

    const type = queryParams.get("type");
    const size = queryParams.get("size");
    const period = queryParams.get("period");
    const nonce = queryParams.get("nonce");

    sessionStorage.setItem("resetting_subscription", "true");
    sessionStorage.setItem("resetting_subscription_nonce", nonce);

    // Parse the 'items' parameter
    const items = {};
    // Reset the subscription
    localStorageUtil.updateObjectItem("subscription", {});

    for (const [key, value] of queryParams.entries()) {
      if (key.startsWith("items[") && key.endsWith("]")) {
        const itemKey = key.slice(6, -1); // Extract the key inside the brackets
        items[itemKey] = value;
      }
    }

    if (type) {
      localStorageUtil.updateObjectItem("subscription", { type });
    }
    if (size) {
      localStorageUtil.updateObjectItem("subscription", { size });
    }
    if (period) {
      localStorageUtil.updateObjectItem("subscription", { period });
    }
    if (type === "curated") {
      const curated = queryParams.get("curated");
      if (curated) {
        localStorageUtil.updateObjectItem("subscription", {
          curated,
        });
      }
    } else {
      console.log(items);
      localStorageUtil.updateObjectItem("subscription", {
        prefilledItems: items,
      });
    }
  }, [queryParams]);

  useEffect(() => {
    if (isNil(subscription_static_data)) {
      getStaticPage("subscription", "subscription_static_data");
    }
    if (!data) {
      setData(subscription_static_data);
    }

    if (subscription_static_data && subscription_static_data.modules !== null) {
      setModules(subscription_static_data.modules);
    }
  }, [subscription_static_data]);

  // Dynamically select the component based on the last URL segment
  const renderComponentBasedOnPath = () => {
    switch (matchedStep) {
      case "size":
        return <SubscriptionSize />;
      case "customize":
        return <CustomizeSubscription />;
      case "curated":
        return <CuratedSubscription />;
      default:
        return (
          <>
            {modules && (
              <LoadModules
                modules={modules}
                filter={filter}
                moduleData={null}
                type="top"
              />
            )}
            <SubscriptionOption />
            {modules && (
              <LoadModules
                modules={modules}
                filter={filter}
                moduleData={null}
                type="bottom"
              />
            )}
          </>
        );
    }
  };

  return (
    <>
      {data && (
        <Meta
          title={data.seo_title ?? data.title}
          description={data.seo_description ?? data.body}
        />
      )}
      {renderComponentBasedOnPath()}
    </>
  );
};

export default flatstore.connect(["loading", "subscription_static_data"])(
  Subscription
);
