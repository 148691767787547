import parse from "html-react-parser";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import W from "../../../components/common/Word";
import {
    getSubscriptionPaths,
    localStorageUtil,
} from "../../../utils/functions";
const { useLocation } = require("react-router-dom");

function SubscriptionOption() {
  const navigate = useNavigate();
  const paths = getSubscriptionPaths();
  const { t } = useTranslation();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  const [toggleStates, setToggleStates] = useState([false, false]);

  const handleToggle = (index) => {
    setToggleStates((prevState) => {
      const newToggleStates = [...prevState];
      newToggleStates[index] = !newToggleStates[index];
      return newToggleStates;
    });
  };

  const [type, setType] = useState(
    queryParams.get("type") ||localStorageUtil.getItem("subscription")?.type || "customize"
  );

  const handleTypeChange = (e) => setType(e.target.value);

  const handleSubmit = () => {
    if (type) {
      localStorageUtil.updateObjectItem("subscription", { type: type });
      navigate(paths.steps["size"]); // Navigate to size step
    }
  };


  return (
    <div className="subscription-box py-6">
      <div className="container">
        <div className="row">
          <div className="col primary-title">
            <h2>
              <W>subscription.option.title</W>
            </h2>
            <p className="pl-2 pr-2">
              <W>subscription.option.subtitle</W>
            </p>
          </div>
        </div>
        <div className="row justify-content-center">
          {["customize", "curated"].map((typeOption, index) => (
            <div key={index} className="col-md-6 col-lg-5 col-xl-5">
              <label
                className="subscription__select subscription__item"
                htmlFor={typeOption}
              >
                <input
                  type="radio"
                  id={typeOption}
                  name="type"
                  value={typeOption}
                  checked={type === typeOption}
                  onChange={handleTypeChange}
                />

                <div className="body">
                  <span className={"icon-" + typeOption}></span>
                  <div className="title">
                    <div className="checkbox">
                      <span></span>
                    </div>
                    <h3 className="heading">
                      {t(`subscription.${typeOption}.title`)}
                    </h3>
                    <p>{t(`subscription.${typeOption}.subtitle`)}</p>
                  </div>
                </div>
                <div
                  className={
                    toggleStates[index] ? "more-info open " : "more-info"
                  }
                >
                  <div className="handle" onClick={() => handleToggle(index)}>
                    <W>subscription.more-info</W>
                  </div>
                  <div className="info-item">
                    <ul>{parse(t(`subscription.${typeOption}.more-info`))}</ul>
                  </div>
                </div>
              </label>
            </div>
          ))}
        </div>
      </div>
      <div className="subscription__cta">
        <button className="btn btn--red" onClick={handleSubmit}>
          <W>subscription.select</W>
        </button>
      </div>
    </div>
  );
}

export default SubscriptionOption;
